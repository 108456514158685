<template>
  <div class="relative isolate overflow-hidden bg-gray-900">
    <header class="absolute inset-x-0 top-0 z-50">
      <nav
        class="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div class="flex lg:flex-1 items-center">
          <div class="-m-1.5 p-1.5">
            <span class="sr-only">Quo LLC</span>
            <img
              class="h-12 w-auto rounded"
              src="/logo/quo-logo-80.png"
              alt=""
            />
          </div>
          <span class="font-bold text-white text-3xl pl-3">Quo</span>
        </div>

        <div class="flex flex-1 justify-end items-center">
          <div class="">
            <Menu as="div" class="relative inline-block">
              <div>
                <MenuButton
                  id="language-menu-button"
                  class="inline-flex w-full justify-center py-2 outline-none"
                >
                  <SvgIcon
                    name="globe-alt"
                    type="outline"
                    class="w-6 h-6 stroke-white"
                  />
                </MenuButton>
              </div>
              <MenuItems
                class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg outline-none"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('en')"
                      >English</NuxtLink
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('de')"
                      >Deutsch</NuxtLink
                    >
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <NuxtLink
            class="text-white text-xs flex ml-4 bg-white/10 py-1 border border-white/30 pl-3 pr-1.5 rounded-full items-center font-medium"
            :to="localePath({ name: 'map' })"
          >
            {{ $t('homepage.toTheMap') }}
            <SvgIcon
              name="chevron-right"
              type="outline"
              class="w-3 h-3 ml-0.5"
            />
          </NuxtLink>
        </div>
      </nav>
    </header>

    <div
      class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-20 xl:left-[calc(50%-24rem)]"
      aria-hidden="true"
    >
      <div
        class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
        style="
          clip-path: polygon(
            73.6% 51.7%,
            91.7% 11.8%,
            100% 46.4%,
            97.4% 82.2%,
            92.5% 84.9%,
            75.7% 64%,
            55.3% 47.5%,
            46.5% 49.4%,
            45% 62.9%,
            50.3% 87.2%,
            21.3% 64.1%,
            0.1% 100%,
            5.4% 51.1%,
            21.4% 63.9%,
            58.9% 0.2%,
            73.6% 51.7%
          );
        "
      />
    </div>
    <div
      class="mx-auto max-w-7xl px-6 pb-16 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40"
    >
      <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
        <div class="h-11">&nbsp;</div>
        <div class="mt-24 sm:mt-32 lg:mt-16">
          <NuxtLink
            to="https://apps.apple.com/app/id1492067223"
            :external="true"
            class="inline-flex space-x-6"
          >
            <span
              class="flex rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20 justify-center items-center"
              >New</span
            >
            <span
              class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300"
            >
              <span>Just shipped Quo for iOS</span>
              <SvgIcon
                name="chevron-right"
                type="solid"
                class="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </span>
          </NuxtLink>
        </div>
        <h1
          class="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl"
        >
          {{ $t('homepage.hero.title') }}
        </h1>
        <p class="mt-6 text-lg leading-8 text-gray-300">
          {{ $t('homepage.hero.lead') }}
        </p>
        <div class="mt-10 flex items-center gap-x-6">
          <a
            href="#pricing"
            class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
            >{{ $t('homepage.hero.cta') }}</a
          >
          <a
            href="#primary-features"
            class="text-sm font-semibold leading-6 text-white"
            >{{ $t('homepage.hero.learnMore') }}
            <span aria-hidden="true">→</span></a
          >
        </div>
      </div>
      <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
        <svg
          viewBox="0 0 366 729"
          role="img"
          class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
        >
          <title>Eiffel tower on Quo</title>
          <defs>
            <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
              <rect width="316" height="684" rx="36" />
            </clipPath>
          </defs>
          <path
            fill="#4B5563"
            d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
          />
          <path
            fill="#343E4E"
            d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
          />
          <foreignObject
            width="316"
            height="684"
            transform="translate(24 24)"
            clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
          >
            <img src="/images/home-mobile-en.png" alt="" />
          </foreignObject>
        </svg>
      </div>
    </div>

    <a name="primary-features"></a>
    <div
      class="bg-gradient-to-b from-white/5 from-10% to-gray-900 to-40% py-24 sm:py-32"
    >
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {{ $t('homepage.primaryFeatures.title') }}
          </h2>
          <p class="mt-6 text-lg leading-8 text-gray-300">
            {{ $t('homepage.primaryFeatures.lead') }}
          </p>
        </div>
      </div>
      <div
        class="flex overflow-x-auto w-full snap-mandatory snap-x scroll-smooth no-scrollbar pt-16 overscroll-x-contain"
      >
        <div
          v-for="(screen, wsIndex) in webScreens"
          :key="wsIndex"
          class="w-10/12 mg:w-7/12 lg:w-5/12 flex-none snap-center px-3"
        >
          <div
            class="bg-white/5 rounded-2xl px-5 py-6 relative transform overflow-hidden"
          >
            <div
              class="aspect-[366/729] mx-auto w-full max-w-[366px] overflow-hidden"
            >
              <svg
                viewBox="0 0 366 729"
                role="img"
                class="pointer-events-none inset-x-0 bottom-40 h-full w-full fill-gray-400"
              >
                <title>Web screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width="316" height="684" rx="36" />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width="316"
                  height="684"
                  transform="translate(24 24)"
                  clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img :src="screen.image" alt="" />
                </foreignObject>
              </svg>
            </div>
            <div
              class="inset-x-0 bottom-0 bg-white-5 sm:px-6 pt-6 backdrop-blur"
            >
              <div class="flex space-x-3">
                <div>
                  <div class="bg-white/5 p-2 rounded">
                    <SvgIcon
                      :name="screen.iconName"
                      :type="screen.iconType"
                      class="h-6 w-6"
                      :class="screen.iconColor"
                    />
                  </div>
                </div>
                <div>
                  <div></div>
                  <h3 class="text-sm font-semibold text-white">
                    {{ screen.title }}
                  </h3>
                  <p class="mt-1 text-sm text-gray-400">
                    {{ screen.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative bg-white">
      <div
        class="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end"
      >
        <div
          class="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2"
        >
          <div
            class="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0"
          >
            <img
              class="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src="/images/daily-feed.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="px-6 lg:contents">
          <div
            class="mx-auto max-w-2xl pt-16 pb-24 sm:pb-0 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2"
          >
            <p class="text-base font-semibold leading-7 text-indigo-600">
              {{ $t('homepage.secondaryFeatures.subtitle') }}
            </p>
            <h1
              class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            >
              {{ $t('homepage.secondaryFeatures.title') }}
            </h1>
            <p class="mt-6 text-xl leading-8 text-gray-700">
              {{ $t('homepage.secondaryFeatures.lead') }}
            </p>
            <div
              class="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none"
            >
              <p>
                {{ $t('homepage.secondaryFeatures.intro') }}
              </p>
              <ul role="list" class="mt-8 space-y-8 text-gray-600">
                <li class="flex gap-x-3">
                  <SvgIcon
                    name="user"
                    type="solid"
                    class="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span
                    ><strong class="font-semibold text-gray-900">{{
                      $t('homepage.secondaryFeatures.pillarOneTitle')
                    }}</strong>
                    {{ $t('homepage.secondaryFeatures.pillarOneText') }}</span
                  >
                </li>
                <li class="flex gap-x-3">
                  <SvgIcon
                    name="users"
                    type="solid"
                    class="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span
                    ><strong class="font-semibold text-gray-900">{{
                      $t('homepage.secondaryFeatures.pillarTwoTitle')
                    }}</strong>
                    {{ $t('homepage.secondaryFeatures.pillarTwoText') }}</span
                  >
                </li>
                <li class="flex gap-x-3">
                  <SvgIcon
                    name="newspaper"
                    type="solid"
                    class="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span
                    ><strong class="font-semibold text-gray-900">{{
                      $t('homepage.secondaryFeatures.pillarThreeTitle')
                    }}</strong>
                    {{ $t('homepage.secondaryFeatures.pillarThreeText') }}</span
                  >
                </li>
                <li class="flex gap-x-3">
                  <SvgIcon
                    name="no-symbol"
                    type="outline"
                    class="mt-1 h-5 w-5 flex-none stroke-red-500 stroke-2"
                  />
                  <span
                    ><strong class="font-semibold text-gray-900">{{
                      $t('homepage.secondaryFeatures.noTitle')
                    }}</strong>
                    {{ $t('homepage.secondaryFeatures.noText') }}</span
                  >
                </li>
              </ul>
              <p class="mt-8">
                {{ $t('homepage.secondaryFeatures.outro') }}
              </p>
            </div>
            <dl
              class="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4 border-b pb-10 lg:border-b-0 lg:pb-0"
            >
              <div v-for="(stat, statIdx) in stats" :key="statIdx">
                <dt class="text-sm font-semibold leading-6 text-gray-600">
                  {{ stat.label }}
                </dt>
                <dd
                  class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900"
                >
                  {{ stat.value }}
                </dd>
              </div>
            </dl>
            <div class="h-10 lg:hidden">&nbsp;</div>
            <div
              class="mt-4 lg:flex gap-x-2 text-sm leading-6 text-gray-500 hidden lg:pt-24"
            >
              <SvgIcon
                name="information-circle"
                type="solid"
                class="mt-0.5 h-5 w-5 flex-none text-gray-300"
                aria-hidden="true"
              />
              <span
                >Image shows Italian
                <NuxtLink
                  class="font-bold"
                  :to="
                    localePath({
                      name: 'map-place-nanoId-slug',
                      params: { nanoId: 'pomx6e1e' },
                    })
                  "
                  >island of Procida</NuxtLink
                >
                &middot;
                <span class="text-gray-400"
                  >© dudlajzov - stock.adobe.com</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <a name="pricing"></a>
    <div class="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div
        class="absolute inset-x-0 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        aria-hidden="true"
      >
        <div
          class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>

      <PricingTiers />
    </div>

    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-6 pb-24 sm:pb-32 lg:px-8 lg:pb-40">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2
            class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
          >
            {{ $t('homepage.faqs.title') }}
          </h2>
          <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
            <ClientOnly>
              <Disclosure
                v-for="faq in faqs"
                :key="faq.question"
                v-slot="{ open }"
                as="div"
                class="pt-6"
              >
                <dt>
                  <DisclosureButton
                    class="flex w-full items-start justify-between text-left text-gray-900"
                  >
                    <span class="text-base font-semibold leading-7">{{
                      faq.question[locale]
                    }}</span>
                    <span class="ml-6 flex h-7 items-center">
                      <SvgIcon
                        v-if="!open"
                        name="plus-small"
                        type="outline"
                        class="h-6 w-6"
                        aria-hidden="true"
                      />
                      <SvgIcon
                        v-else
                        name="minus-small"
                        type="outline"
                        class="h-6 w-6"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" class="mt-2 pr-12">
                  <p class="text-base leading-7 text-gray-600">
                    {{ faq.answer[locale] }}
                  </p>
                </DisclosurePanel>
              </Disclosure>
            </ClientOnly>
          </dl>
        </div>
      </div>
    </div>

    <div class="bg-gray-900 py-24 sm:py-32">
      <div class="relative isolate">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div
            class="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20"
          >
            <img
              class="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
              src="/images/beach.jpeg"
              alt=""
            />
            <div class="w-full flex-auto">
              <h2
                class="text-3xl font-bold tracking-tight text-white sm:text-4xl"
              >
                {{ $t('homepage.embark.title') }}
              </h2>
              <p class="mt-6 text-lg leading-8 text-gray-300">
                {{ $t('homepage.embark.lead') }}
              </p>

              <div class="mt-10 flex">
                <NuxtLink
                  class="text-sm font-semibold leading-6 text-indigo-400"
                  :to="localePath({ name: 'auth-checkout-choose-product' })"
                  >{{ $t('homepage.embark.cta') }}
                  <span aria-hidden="true">&rarr;</span></NuxtLink
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style="
              clip-path: polygon(
                73.6% 51.7%,
                91.7% 11.8%,
                100% 46.4%,
                97.4% 82.2%,
                92.5% 84.9%,
                75.7% 64%,
                55.3% 47.5%,
                46.5% 49.4%,
                45% 62.9%,
                50.3% 87.2%,
                21.3% 64.1%,
                0.1% 100%,
                5.4% 51.1%,
                21.4% 63.9%,
                58.9% 0.2%,
                73.6% 51.7%
              );
            "
          />
        </div>
      </div>
    </div>

    <footer class="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div class="md:grid md:grid-cols-4 md:gap-8">
          <div class="space-y-8">
            <div class="flex">
              <img
                class="h-7 rounded"
                src="/logo/quo-logo-80.png"
                alt="Quo LLC"
              />
              <span class="font-bold text-white text-lg pl-3">Quo</span>
            </div>

            <p class="text-sm leading-6 text-gray-300">
              Keep all your places in one place.
            </p>
            <div class="flex space-x-6">
              <NuxtLink
                v-for="item in footerNavigation.social"
                :key="item.name"
                :to="item.to"
                class="text-gray-500 hover:text-gray-400"
              >
                <span class="sr-only">{{ item.name }}</span>
                <SvgIcon
                  :name="item.iconName"
                  type="solid"
                  class="h-6 w-6 text-gray-500 hover:text-gray-400"
                  aria-hidden="true"
                />
              </NuxtLink>
            </div>
          </div>
          <div
            class="mt-16 grid grid-cols-2 sm:grid-cols-3 gap-8 md:col-span-3 md:mt-0"
          >
            <div class="">
              <h3 class="text-sm font-semibold leading-6 text-white">
                Products
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.products" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
            <div class="">
              <h3 class="text-sm font-semibold leading-6 text-white">
                Company
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.company" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.legal" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p class="text-xs leading-5 text-gray-400">
            &copy; 2023 Quo LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue'

definePageMeta({
  layout: 'empty',
})

const { $metric } = useNuxtApp()

const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()
const { locale } = useI18n()

const webScreens = computed(() => {
  // only return the current locale, sorted by sortOrder
  return webScreensAll
    .filter((ws) => ws.translations.some((t) => t.locale === locale.value))
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((ws) => {
      const translation = ws.translations.find((t) => t.locale === locale.value)
      return {
        id: ws.id,
        iconName: ws.iconName,
        iconColor: ws.iconColor,
        title: translation.title,
        laterText: translation.laterText,
        description: translation.description,
        image: translation.image,
        isQuoProFeature: ws.isQuoProFeature,
      }
    })
})

const webScreensAll = [
  {
    id: 1,
    iconName: 'map-pin',
    iconColor: 'text-white',
    iconType: 'outline',
    translations: [
      {
        locale: 'en',
        title: 'Add places',
        laterText: 'new',
        description:
          'Simply add any public place you like to visit. Create it right on the map, or paste in the GPS cordinates.',
        image: '/images/home-add-place-en.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 1,
  },
  {
    id: 2,
    iconName: 'list-bullet',
    iconColor: 'text-white',
    iconType: 'solid',
    translations: [
      {
        locale: 'en',
        title: 'Organize in lists',
        laterText: 'new',
        description:
          'Create lists for your next trip, local businesses, your favorite places to eat, or your life bucket list.',
        image: '/images/home-list-en.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 2,
  },
  {
    id: 3,
    iconName: 'heart',
    iconColor: 'text-red-500',
    iconType: 'outline',
    translations: [
      {
        locale: 'en',
        title: 'Like it or not',
        laterText: 'new',
        description:
          'Bring in some emotions. Like or dislike places, and show how you feel about them. It’s all about you.',
        image: '/images/home-like-en.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 3,
  },
  {
    id: 4,
    iconName: 'chat-bubble-left',
    iconColor: 'text-white',
    iconType: 'outline',
    translations: [
      {
        locale: 'en',
        title: 'Notes & comments',
        laterText: 'new',
        description:
          'Add notes and comments - for yourself, your friends, or anybody who appreciates your tips.',
        image: '/images/home-note-en.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 4,
  },
  {
    id: 5,
    iconName: 'check',
    iconColor: 'text-white',
    iconType: 'solid',
    translations: [
      {
        locale: 'en',
        title: 'Checkins',
        laterText: 'new',
        description:
          'Checkin to places you visit, and create the story of your life. Keep fond memories of the journey of your life.',
        image: '/images/home-checkin-en.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 5,
  },
  {
    id: 6,
    iconName: 'users',
    iconColor: 'text-white',
    iconType: 'solid',
    translations: [
      {
        locale: 'en',
        title: 'Share',
        laterText: 'Later this year',
        description:
          'Share your stuff with friends, family, or the world. It’s up to you. Collaborate on lists, and create memories together.',
        image: '/images/web-mobile.png',
      },
    ],
    isQuoProFeature: true,
    sortOrder: 6,
  },
]

const stats = [
  {
    id: 1,
    name: 'Creators on the platform',
    value: '2435',
    label: 'Places',
  },
  { id: 2, name: 'Flat platform fee', value: '21', label: 'Cities' },
  { id: 3, name: 'Uptime guarantee', value: '13', label: 'Countries' },
  { id: 4, name: 'Paid out to creators', value: 'zero', label: 'Paid content' },
]

const faqs = [
  {
    id: 1,
    question: { en: 'Are my data shared with anybode else?' },
    answer: {
      en: "Quo does not and will not share, sell or lend your data to anybody else. We don't even use any external fonts, images, analytics or other tools. We do link to (big tech) websites (like Google, Facebook, Instagram, X - to name a few), but it's up to you to click on them.",
    },
  },
  {
    id: 2,
    question: { en: 'Is there an iOS or Android App?' },
    answer: {
      en: 'There was an iOS App, which we will update as soon as this website is feature complete of the current batch.',
    },
  },
  {
    id: 3,
    question: { en: 'Why does this cost a thing??' },
    answer: {
      en: 'We opt for the most transparent business model. That is a flat fee in exchange for an incredibly modern and rich map experience. No one is exempt from this model - not even our CEO. He personally funds it from his own travel budget. The costs are no more than the initial kilometers in an Uber. Except that Quo takes your everywhere.',
    },
  },
]

const footerNavigation = {
  products: [
    { name: 'Quo for individuals', href: '#' },
    { name: 'Quo for organizations (soon)', href: '#' },
  ],
  company: [
    {
      name: 'Team',
      to: localePath({
        name: 'company-team',
      }),
    },
    {
      name: 'Contact',
      to: localePath({
        name: 'support',
      }),
    },
  ],
  legal: [
    {
      name: 'Terms of Service',
      to: localePath({
        name: 'legal-terms-of-service',
      }),
    },
    {
      name: 'Privacy Policy',
      to: localePath({
        name: 'legal-privacy-policy',
      }),
    },
    {
      name: 'Imprint',
      to: localePath({
        name: 'legal-imprint',
      }),
    },
  ],
  social: [
    {
      name: 'X',
      to: 'https://x.com/quo_space',
      iconName: 'x-corp',
    },
    {
      name: 'Pinterest',
      to: 'https://www.pinterest.com/quospace',
      iconName: 'pinterest',
    },
    {
      name: 'LinkedIn',
      to: 'https://www.linkedin.com/company/quo-space/',
      iconName: 'linkedin',
    },
  ],
}

if (process.client) {
  await $metric({ key: 'PAGE_VIEW' })
}
</script>
